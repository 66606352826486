import React from 'react';
import Edit from '../../models/clients/EditLicense';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ClientService from "../../services/clients.service";
import LicenseService from "../../services/licenses.service";

import AuthService from "../../services/auth.service";

export default function DetailsLicense(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [client, setClient] = React.useState({

        "clientId": 0,
        "name": "",
        "remarks": "",
        "isDisabled": false

    });

    const [license, setLicense] = React.useState({

        "allowUpdates": false,
        "appId": "",
        "creationDate": Date.now.toJSON,
        "licenseCode": "",
        "licenseId": -1,
        "isDisabled": false,
        "clientId": -1,
        "licenseRequestId": "",
        "machineCode": "",
        "remarks": "",
        "maxVersion": "",
        "clientName": ""

    });

    React.useEffect(() => {

        ClientService.getClient(params.clientId)
            .then(response => response.data)
            .then(json => {
                setClient({

                    "clientId": json.contract.clientId,
                    "name": json.contract.name,
                    "remarks": json.contract.remarks,
                    "isDisabled": json.contract.isDisabled

                });

            });
    }, [params.clientId]);


    React.useEffect(() => {

        if (params.licenseId > -1) {
            LicenseService.getLicenseById(params.licenseId)
                .then(response => response.data)
                .then(json => {
                    setLicense({
                        "allowUpdates": json.contract.allowUpdates,
                        "appId": json.contract.appId,
                        "creationDate": json.contract.creationDate,
                        "licenseCode": json.contract.licenseCode,
                        "licenseId": json.contract.licenseId,
                        "isDisabled": json.contract.isDisabled,
                        "clientId": json.contract.clientId,
                        "licenseRequestId": json.contract.licenseRequestId,
                        "machineCode": json.contract.machineCode,
                        "remarks": json.contract.remarks ?? "",
                        "maxVersion": json.contract.maxVersion ?? "",
                        "clientName": json.contract.clientName
                    });

                });
        }
        else {
            LicenseService.getLicenseByRequestId(params.licenseRequestId)
                .then(response => response.data)
                .then(json => {
                    setLicense({
                        "allowUpdates": json.contract.allowUpdates,
                        "appId": json.contract.appId,
                        "creationDate": json.contract.creationDate,
                        "licenseCode": json.contract.licenseCode,
                        "licenseId": json.contract.licenseId,
                        "isDisabled": json.contract.isDisabled,
                        "clientId": json.contract.clientId,
                        "licenseRequestId": json.contract.licenseRequestId,
                        "machineCode": json.contract.machineCode,
                        "remarks": json.contract.remarks ?? "",
                        "maxVersion": json.contract.maxVersion ?? "",
                        "clientName": json.contract.clientName
                    });

                });
        }

    }, [params.licenseId, params.licenseRequestId]);


    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/Home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/'}>{t("Clients_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/' + client.clientId + '/Details'}>{client.name}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/' + client.clientId + '/Licenses'}>{t("Clients_Licenses_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Clients/' + client.clientId + '/Licenses/' + params.licenseId + '/Details'}>{params.licenseId}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Clients/' + client.clientId + '/Details/'} className="nav-link">{t("Clients_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + client.clientId + '/Contracts'} className="nav-link">{t("Clients_Contracts_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Clients/' + client.clientId + '/Licenses'} className="nav-link active">{t("Clients_Licenses_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div className="card">
                        <div className="card-header">
                            <ul className="nav nav-tabs card-header-tabs">
                                <li className="nav-item">
                                    <Link to={'/Clients/' + client.clientId + '/Licenses/' + params.licenseId + '/Details'} className="nav-link active">{t("Clients_Licenses_Details_Title")}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/Clients/' + client.clientId + '/Licenses/' + params.licenseId + '/Tags'} className="nav-link">{t("Clients_Licenses_Tags_Title")}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <Edit
                                license={license}
                                isAdmin={AuthService.isUserAdmin()}
                                client={client}
                                licenseId={params.licenseId}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />
        </div >
    );

}
