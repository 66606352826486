import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from "react-i18next";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { CustomRedirectListCommandCell } from "../../components/GridCustomRedirectListCommandCell";
import { CustomInput, CustomDatePickerInput, CustomTextArea, CustomCheckbox, CustomDropDown } from "../../components/CustomComponents"
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";

import ContractsService from "../../services/contracts.service";
import ClientContractsService from "../../services/clientContracts.service";
import ContractTypesService from "../../services/contractType.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";
import canumbers from "cldr-numbers-full/main/ca/numbers.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian,
    canumbers

);


loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function Edit(props) {

    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    const [clients, setClients] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState({ clientId: -1, name: "", remarks: '' });

    const [contractTypes, setContractTypes] = React.useState([]);
    const [selectedContractType, setSelectedContractType] = React.useState({ contractTypeId: -1, name: "", remarks: '' });

    const [contractLicences, setContractLicences] = React.useState([]);
    const [notContractLicences, setNotContractLicences] = React.useState([]);

    const [licensesToDelete, setLicensesToDelete] = React.useState([]);
    const [selectedLicense, setSelectedLicense] = React.useState(null);
    const [licensesToAdd, setLicensesToAdd] = React.useState([]);


    const [contractData, setContractData] = React.useState({
        "contractId": "",
        "clientId": -1,
        "isDisabled": true,
        "creationDate": new Date(),
        "startDate": new Date(),
        "endDate": new Date(),
        "remarks": "",
        "description": ""
    });


    const [contractEdit, setContractEdit] = React.useState({
        "contractId": "",
        "clientId": -1,
        "isDisabled": true,
        "creationDate": new Date(),
        "startDate": new Date(),
        "endDate": new Date(),
        "remarks": "",
        "description": ""
    });

    React.useEffect(() => {
        const getData = async () => {
            let listOfClients = await ContractsService.getAllClients();
            setSelectedClient(listOfClients.find(x => x.clientId === props.contractData.clientId) ?? { clientId: -1 });
            setClients(listOfClients);
        }

        const getDataContractTypes = async () => {
            let listOfContractTypes = await ContractTypesService.getListOfContractTypes();

            // Filtrar los contratos que no están deshabilitados
            let filteredContractTypes = listOfContractTypes.filter(x => x.isDisabled === false);

            // Verificar si el contrato actual está deshabilitado
            let selectedContract = filteredContractTypes.find(x => x.contractTypeId === props.contractData.contractTypeId);

            if (!selectedContract) {
                setSelectedContractType({ contractTypeId: -1, name: "⚠ Contrato deshabilitado, selecciona otro" });
            } else {
                setSelectedContractType(selectedContract);
            }

            setContractTypes(filteredContractTypes);

        }

        if (props.contractData.contractId !== "") {

            setContractData({
                "contractId": props.contractData.contractId,
                "contractTypeId": props.contractData.contractTypeId,
                "clientId": props.contractData.clientId,
                "isDisabled": props.contractData.isDisabled,
                "creationDate": new Date(props.contractData.creationDate),
                "startDate": new Date(props.contractData.startDate),
                "endDate": new Date(props.contractData.endDate),
                "remarks": props.contractData.remarks,
                "description": props.contractData.description
            });
            setContractEdit({
                "contractId": props.contractData.contractId,
                "contractTypeId": props.contractData.contractTypeId,
                "clientId": props.contractData.clientId,
                "isDisabled": props.contractData.isDisabled,
                "creationDate": new Date(props.contractData.creationDate),
                "startDate": new Date(props.contractData.startDate),
                "endDate": new Date(props.contractData.endDate),
                "remarks": props.contractData.remarks,
                "description": props.contractData.description
            });
            getData();
            getDataContractTypes();

        }
    }, [props.contractData]);

    const loadLicenses = React.useCallback(() => {
        if (selectedClient.clientId !== -1)
            ClientContractsService.getClientContractLicenses(selectedClient.clientId, props.contractData.contractId)
                .then(json => {
                    let licencesInContract = [];
                    let licencesNotInContract = [];
                    if (json.data.contract)
                        for (let item of json.data.contract) {

                            item.description = '(' + item.licenseId + ') ' + item.appId

                            if (item.inContract)
                                licencesInContract.push(item);
                            else
                                licencesNotInContract.push(item);
                        }
                    setContractLicences(licencesInContract);
                    setNotContractLicences(licencesNotInContract);
                    setLicensesToAdd([]);
                    setLicensesToDelete([]);
                    setLoading(false);
                });
    }, [props, selectedClient]);


    const addLicense = React.useCallback(() => {

        if (selectedLicense !== null) {
            setNotContractLicences(notContractLicences.filter(y => y.licenseId !== selectedLicense.licenseId));
            setContractLicences([...contractLicences, selectedLicense]);
            setLicensesToAdd([...licensesToAdd, selectedLicense]);
            setLicensesToDelete(licensesToDelete.filter(y => y.licenseId !== selectedLicense.licenseId));
            setSelectedLicense(null);
        }
    }, [licensesToAdd, notContractLicences, contractLicences, licensesToDelete, selectedLicense]);

    const removeLicenseWrapper = React.useCallback((dataItem) => {

        setContractLicences(contractLicences.filter(y => y.licenseId !== dataItem.licenseId));
        setNotContractLicences([...notContractLicences, { ...dataItem, alreadyIn: true }]);
        setLicensesToAdd(licensesToAdd.filter(y => y.licenseId !== dataItem.licenseId));
        setLicensesToDelete([...licensesToDelete, dataItem]);
        setSelectedLicense(null);

    }, [contractLicences, licensesToAdd, licensesToDelete, notContractLicences]);

    const CommandCell = React.useCallback((cellProps) => (
        <CustomRedirectListCommandCell
            {...cellProps}
            editField='inEdit'
            linkToDetails={'/licenses/' + cellProps.dataItem.licenseId + '/""/Details'}
            remove={removeLicenseWrapper}
            isAdmin={props.isAdmin}
        />
    ), [props.isAdmin, removeLicenseWrapper]);

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    }, []);

    const handleSubmitWrapper = React.useCallback(async () => {

        setSaving(true);

        try {

            let returnData = await ContractsService.updateContract(contractData.contractId, {
                "isDisabled": contractEdit.isDisabled,
                "clientId": selectedClient.clientId,
                "contractTypeId": selectedContractType.contractTypeId,
                "creationDate": new Date(contractEdit.creationDate),
                "startDate": new Date(contractEdit.startDate),
                "endDate": new Date(contractEdit.endDate),
                "remarks": contractEdit.remarks,
                "description": contractEdit.description,
                "contractId": contractData.contractId
            });

            let dataFor = {
                "contractId": returnData.data.contract.contractId,
                "contractTypeId": returnData.data.contract.contractId,
                "creationDate": new Date(returnData.data.contract.creationDate),
                "endDate": new Date(returnData.data.contract.endDate),
                "startDate": new Date(returnData.data.contract.startDate),
                "remarks": returnData.data.contract.remarks,
                "isDisabled": returnData.data.contract.isDisabled,
                "description": returnData.data.contract.description,
                "clientId": returnData.data.contract.clientId
            };

            setContractEdit(dataFor);
            setContractData(dataFor);
            setLoading(false);

            for (let licToDelete of licensesToDelete)
                await ClientContractsService.deleteClientContractLicense(selectedClient.clientId, contractData.contractId, licToDelete.licenseId);

            for (let licToAdd of licensesToAdd)
                if (!licToAdd.alreadyIn)
                    await ClientContractsService.saveClientContractLicense(selectedClient.clientId, contractData.contractId, licToAdd.licenseId);

            loadLicenses();

            setSaving(false);


        } catch (err) {
            setVisibleError(true);
            setLoading(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        }
    }, [contractData, contractEdit, selectedClient, licensesToAdd, licensesToDelete, loadLicenses, selectedContractType]);

    const saveDescription = React.useCallback(e => {
        setContractEdit(prev => ({ ...prev, description: e.value }));
    }, []);


    const saveIsDisabled = React.useCallback(e => {
        setContractEdit(prev => ({ ...prev, isDisabled: e.value }));
    }, []);

    const saveCreationDate = React.useCallback(e => {
        setContractEdit(prev => ({ ...prev, creationDate: e.value }));
    }, []);

    const saveStartDate = React.useCallback(e => {
        setContractEdit(prev => ({ ...prev, startDate: e.value }));
    }, []);

    const saveEndDate = React.useCallback(e => {
        setContractEdit(prev => ({ ...prev, endDate: e.value }));
    }, []);

    const saveRemarks = React.useCallback(e => {
        setContractEdit(prev => ({ ...prev, remarks: e.value }));
    }, []);

    const saveContractLicenses = React.useCallback(e => {
        let obt = notContractLicences.find(x => x.licenseId === e.value.licenseId);
        if (obt)
            setSelectedLicense(obt);
    }, [notContractLicences]);

    const editorForm = React.useCallback((formRenderProps) => {

        const saveClient = (e) => {
            setSelectedClient(e.value);
            formRenderProps.onChange("client", { value: e.value });
        };

        const saveContractType = (e) => {
            setSelectedContractType(e.value);
            formRenderProps.onChange("contractType", { value: e.value });
        }

        return (
            <FormElement>
                <fieldset>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <b>{t("Contract_Details_Label")}</b>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-2">
                                    <Field
                                        label={t("Contract_ContractId_Label")}
                                        name={'contractId'}
                                        loading={loading}
                                        component={CustomInput}
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-sm-10">
                                    <Field
                                        label={t("Contract_Description_Label")}
                                        name={'description'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomInput}
                                        onChange={saveDescription}
                                    />
                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    {loading ? <Skeleton /> : <DropDownList
                                        label={t("Contract_Client_Label")}
                                        name={'client'}
                                        loading={loading}
                                        data={clients}
                                        value={selectedClient}
                                        textField="name"
                                        dataItemKey="clientId"
                                        disabled={true}
                                        component={CustomDropDown}
                                        onChange={saveClient}
                                    />}
                                </div>
                                <div className="col-sm-4">
                                    {loading ? <Skeleton /> : <DropDownList
                                        label={t("Contract_ContractType_Label")}
                                        name={'contractType'}
                                        loading={loading}
                                        data={contractTypes}
                                        value={selectedContractType}
                                        textField="name"
                                        dataItemKey="contractTypeId"
                                        component={CustomDropDown}
                                        onChange={saveContractType}
                                    />}
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_IsDisabled_Label")}
                                        name={'isDisabled'}
                                        loading={loading}
                                        disabled={!props.isAdmin}
                                        component={CustomCheckbox}
                                        onChange={saveIsDisabled}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_CreationDate_Label")}
                                        name={'creationDate'}
                                        disabled={!props.isAdmin}
                                        loading={loading}
                                        format={"dd MMMM yyyy"}
                                        component={CustomDatePickerInput}
                                        onChange={saveCreationDate}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_StartDate_Label")}
                                        name={'startDate'}
                                        disabled={!props.isAdmin}
                                        loading={loading}
                                        format={"dd MMMM yyyy"}
                                        component={CustomDatePickerInput}
                                        onChange={saveStartDate}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        label={t("Contract_EndDate_Label")}
                                        name={'endDate'}
                                        disabled={!props.isAdmin}
                                        loading={loading}
                                        format={"dd MMMM yyyy"}
                                        component={CustomDatePickerInput}
                                        onChange={saveEndDate}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <Field
                                        label={t("Contract_Remarks_Label")}
                                        name={'remarks'}
                                        loading={loading}
                                        component={CustomTextArea}
                                        onChange={saveRemarks}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <br />
                {props.isAdmin ? <div className="row">
                    <div className="col-sm-4">
                        <DropDownList
                            textField={'description'}
                            data={notContractLicences}
                            value={selectedLicense}
                            onChange={saveContractLicenses}
                        />
                    </div>
                    <div className="col-sm-1">
                        <Button
                            onClick={addLicense}
                            disabled={selectedLicense === null}
                        >{t("Global_Button_Text_Add")}</Button>
                    </div>
                </div> : ""}
                <br />
                {loading ? <Skeleton /> : <Grid
                    editField='inEdit'
                    cellRender={loadingCell}
                    data={contractLicences}
                    total={contractLicences.length}
                    scrollable={'none'}

                >
                    {props.isAdmin ? <Column cell={CommandCell} width={"80px"} sortable={false} /> : ""}
                    <Column field="licenseId" title={t("Licenses_LicenseId_Label")} />
                    <Column field="appId" title={t("Licenses_AppId_Label")} />

                </Grid>}
                <br />
                {props.isAdmin ? <div className="float-end k-form-buttons">
                    {saving ?
                        <button
                            type={'submit'}
                            disabled={true}
                            className="k-button">
                            <Loader size="small" type="converging-spinner" themeColor="inverse" /> &nbsp; {t("Global_Button_Text_Saving")}
                        </button>
                        :
                        <button
                            onClick={handleSubmitWrapper}
                            className="k-button"
                        >
                            <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                        </button>}
                </div> : ""}
            </FormElement>)
    }, [CommandCell, addLicense, clients, handleSubmitWrapper,
        loading, loadingCell, notContractLicences, contractLicences, props, saveCreationDate, saveEndDate,
        saveDescription, saveIsDisabled, saveStartDate, saving, selectedClient, selectedLicense, t, saveContractLicenses,
        saveRemarks, contractTypes, selectedContractType]);



    const closeErrorWrapper = React.useCallback(() => { setVisibleError(false) }, []);

    React.useEffect(loadLicenses, [props, selectedClient, loadLicenses]);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorWrapper}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorWrapper}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <Form
                        onSubmit={handleSubmitWrapper}
                        initialValues={contractData}
                        key={JSON.stringify(contractData)}
                        render={editorForm}
                    />
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
