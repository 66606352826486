import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'

import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { FloatingLabel } from "@progress/kendo-react-labels";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy, toODataString } from '@progress/kendo-data-query';
import { LocalizationProvider, loadMessages, load, IntlProvider } from '@progress/kendo-react-intl';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { ListOfPaged, MinimumPage } from "../../classes/consts";
import { CustomListCommandCellThreeButtons } from "../../components/GridCustomListCommandCellThreeButtons";

import LicenceRequestsService from "../../services/licenserequests.service";
import ClientsService from "../../services/clients.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import cadateFields from "cldr-dates-full/main/ca/dateFields.json";
import catimeZoneNames from "cldr-dates-full/main/ca/timeZoneNames.json";
import caCAGregorian from "cldr-dates-full/main/ca/ca-gregorian.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames,
    cadateFields,
    catimeZoneNames,
    caCAGregorian

);

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');


export default function List(props) {

    const { t } = useTranslation();

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);
    const [refresh, setRefresh] = React.useState(false);

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Data Hooks
    const [filterData, setFilterData] = React.useState({ created: null, applicationId: -1, clientId: 0, companyName: "" });
    const [clientsData, setClientsData] = React.useState([]);
    const [applicationsData, setApplicationsData] = React.useState([]);
    const [selectedApplication, setSelectedApplication] = React.useState(null);

    // Grid Hooks
    const [filterByString, setFilterByString] = React.useState("");
    const [sortState, setSortState] = React.useState([{ field: "creationDate", dir: "desc" },]);
    const [pageState, setPageState] = React.useState({ skip: 0, take: MinimumPage() });
    const [total, setTotal] = React.useState(0);

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });
    //Filter Hook
    const [loadingClients, setLoadingClients] = React.useState(true);
    const [clientsFilter, setClientsFilter] = React.useState([]);
    const [clientsFilterNames, setClientsFilterNames] = React.useState([]);
    const [stateClientFilter, setStateClientFilter] = React.useState({ data: clientsFilterNames, value: "", });
    const [selectedClientFilter, setSelectedClientFilter] = React.useState("");

    // Aspect Functions

    const CommandCell = React.useCallback(cellProps => (
        <CustomListCommandCellThreeButtons
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/LicenseRequests/' + cellProps.dataItem.licenseRequestId + '/Details'}
            isAdmin={props.isAdmin}
            hasLicense={cellProps.dataItem.existLicense}
            linkToLicense={'/licenses/-1/' + cellProps.dataItem.licenseRequestId + '/Details'}
            linkToNewLicense={'/licenses/' + cellProps.dataItem.licenseRequestId + '/Create'}

        />
    ), [props]);

    const loadingPanel =
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>;

    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }
        // default rendering for this cell
        return tdElement;
    }, []);

    React.useEffect(() => {
        if (applicationsData.length > 0 && clientsFilter.length > 0) {
            const storedFilterState = sessionStorage.getItem("license_requests_list");

            if (storedFilterState) {
                const license_requests_list = JSON.parse(storedFilterState);

                // Establecer los filtros para Application y Fecha
                setSelectedClientFilter(license_requests_list.clientFilter);
                setFilterData(prev => ({
                    ...prev,
                    applicationId: license_requests_list.applicationId,
                    created: license_requests_list.createdDate ? new Date(license_requests_list.createdDate) : null,
                }));

                // Establecer el filtro de aplicación usando la lógica de FilterByClient
                let clientName = license_requests_list.clientFilter
                    ? license_requests_list.clientFilter.replace(/CompanyName eq '(.*)'/, "$1")
                    : "";

                // Buscar el cliente en la lista
                let clientSelected = clientsFilter.find(client => client.name === clientName);

                if (clientSelected) {
                    // Aquí aplicamos la lógica de FilterByClient
                    setStateClientFilter({
                        data: filterDataByClient(clientSelected.name),
                        value: clientSelected.name,
                    });
                } else {
                    // Si no se encuentra el cliente, dejamos los valores por defecto
                    setStateClientFilter({
                        data: filterDataByClient(""),
                        value: "",
                    });
                }

                // Establecer el filtro de aplicación
                setSelectedApplication(
                    applicationsData.find(app => app.applicationId === license_requests_list.applicationId) ||
                    { applicationId: -1, applicationName: t("Global_DropDownFilter_None_Selected") }
                );

                // Construcción del filtro para OData
                let filter = "";

                if (license_requests_list.clientFilter)
                    filter += license_requests_list.clientFilter;

                if (license_requests_list.applicationId !== -1)
                    filter += (filter ? " and " : "") + `ApplicationId eq ${license_requests_list.applicationId}`;

                if (license_requests_list.companyName)
                    filter += (filter ? " and " : "") + `Contains(CompanyName, '${license_requests_list.companyName}')`;

                if (license_requests_list.createdDate)
                    filter += (filter ? " and " : "") + `CreationDate ge ${new Date(license_requests_list.createdDate).toJSON()}`;

                console.log(filter);
                setFilterByString(filter);
            }
        }
    }, [applicationsData, clientsFilter]);


    React.useEffect(() => {

        setLoadingClients(true);

        ClientsService.getClients()
            .then(response => response.data)
            .then(json => {
                json.splice(0, 0, { clientId: null, name: "", remarks: "" })
                setLoadingClients(false);
                json.splice(0, 0, { clientId: null, name: t("Licenses_ClientFilter_All_Text"), remarks: "" })
                setClientsFilter(json);
                setClientsFilterNames(json.map((e) => e.name));
                setLoadingClients(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });

        LicenceRequestsService.getListOfApplications().then(data => {
            data.push({ appId: "", applicationName: t("Global_DropDownFilter_None_Selected") });
            setSelectedApplication({ appId: "", applicationName: t("Global_DropDownFilter_None_Selected") });
            setApplicationsData(data);
        });
    }, [t]);

    React.useEffect(() => {
        setLoading(true);
        setRefresh(false);
        let sortStateString = toODataString({ sort: sortState });
        sortStateString = sortStateString.replace("name", "Name");
        sortStateString = sortStateString.replace("companyName", "CompanyName");
        sortStateString = sortStateString.replace("creationDate", "CreationDate");
        sortStateString = sortStateString.replace("$orderby", "?orderBy");

        LicenceRequestsService.getLicenseRequestsFiltered(filterByString, sortStateString, Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
            .then(response => response.data)
            .then(json => {
                json.contract.map((item) => (item.creationDate = new Date(item.creationDate)))
                setClientsData(json.contract);
                setTotal(json.totalResults);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });
    }, [pageState, filterByString, sortState, refresh]);


    const remove = (dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }

    React.useEffect(() => {
        LicenceRequestsService.getListOfApplications().then(data => {
            data.push({ applicationId: -1, applicationName: t("Global_DropDownFilter_None_Selected") });
            setSelectedApplication({ applicationId: -1, applicationName: t("Global_DropDownFilter_None_Selected") });
            setApplicationsData(data);
        });
    }, [t]);

    const removeDataItem = () => {
        LicenceRequestsService.deleteLicenseRequest(dataItemToRemove.licenseRequestId).then(() => {
            setDataItemToRemove({});
            setRefresh(true);
            setVisibleRemove(false);
        }).catch((err) => {
            setVisibleError(true);
            setDataItemToRemove({});
            setVisibleRemove(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
        });
    }

    const clickFilter = () => {
        let filter = "";

        if (selectedClientFilter !== "")
            filter += selectedClientFilter;

        if (filterData.applicationId !== -1)
            if (filter === "")
                filter += "ApplicationId eq " + filterData.applicationId + "";
            else
                filter += " and ApplicationId eq " + filterData.applicationId + "";

        if (filterData.companyName !== "")
            if (filter === "")
                filter += 'Contains(CompanyName,\'' + filterData.companyName + '\')';
            else
                filter += " and Contains(CompanyName,'" + filterData.companyName + "')";

        if (filterData.created !== null)
            if (filter === "")
                filter += "CreationDate ge " + filterData.created.toJSON(); // Esto está bien si created es un objeto Date
            else
                filter += " and CreationDate ge " + filterData.created.toJSON();

        console.log(filter);

        setFilterByString(filter);

        // Guardar los filtros en sessionStorage
        const license_requests_list = {
            clientFilter: selectedClientFilter,
            applicationId: filterData.applicationId,
            companyName: filterData.companyName,
            createdDate: filterData.created ? filterData.created.toISOString() : null, // Convertimos a ISO string
        };

        sessionStorage.setItem("license_requests_list", JSON.stringify(license_requests_list));
    };


    const clearFilters = () => {
        sessionStorage.removeItem("license_requests_list");

        setSelectedClientFilter("");
        setFilterData({
            applicationId: -1,
            companyName: "",
            created: null,
        });

        setStateClientFilter({
            data: [],
            value: "",
        });

        setSelectedApplication({ applicationId: -1, applicationName: t("Global_DropDownFilter_None_Selected") });
        setFilterByString("");
    };


    const filterDataByClient = React.useCallback((value) => {
        const filter = {
            value: value,
            operator: "startswith",
            ignoreCase: true,
        };

        return value ? filterBy(clientsFilterNames, filter) : clientsFilterNames;
    }, [clientsFilterNames]);

    const FilterByClient = React.useCallback((client) => {

        console.log(client)
        let clientSelected = clientsFilter.find(obj => {
            return obj.name === client;
        })

        console.log(clientSelected)

        if (clientSelected != null && clientSelected?.name !== "") {

            setSelectedClientFilter("CompanyName eq \'" + clientSelected.name + "\'");

            setStateClientFilter({
                data: filterDataByClient(clientSelected.name),
                value: clientSelected.name,
            });

        } else {

            setSelectedClientFilter("");
            setStateClientFilter({
                data: filterDataByClient(client),
                value: client,
            });
        }

    }, [clientsFilter, filterDataByClient]);



    const filterClient = React.useCallback(e => FilterByClient(e.target.value), [FilterByClient]);


    const sortChange = React.useCallback((event) => {
        setSortState(event.sort);
    }, []);

    const pageChange = React.useCallback((event) => {
        setPageState(prev => ({ ...prev, skip: event.page.skip, take: event.page.take }));
    }, []);

    const closeErrorCallback = React.useCallback(() => { setVisibleError(false) }, []);
    const closeRemoveCallback = React.useCallback(() => { setVisibleRemove(false); }, []);

    const filterAppId = React.useCallback(e => {
        setSelectedApplication(e.value);
        setFilterData(prev => ({ ...prev, applicationId: e.value.applicationId }));
    }, []);
    const filterCreationDate = React.useCallback(e => {
        setFilterData(prev => ({ ...prev, created: new Date(e.value) }));
    }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeErrorCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={closeErrorCallback}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={closeRemoveCallback}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.licenseRequestId + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => removeDataItem()}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={closeRemoveCallback}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <IntlProvider locale={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-2">
                                    <FloatingLabel
                                        style={{ width: "100%" }}
                                        label={t("LicenseRequests_companyName_Label")}
                                        editorId={"companyFilterSelector"}
                                        editorValue={loading ? "" : "null"}
                                    >
                                        <AutoComplete
                                            id={"companyFilterSelector"}
                                            style={{ width: "100%" }}
                                            loading={loadingClients}
                                            data={stateClientFilter.data}
                                            value={stateClientFilter.value}
                                            onChange={filterClient}
                                        />
                                    </FloatingLabel>
                                </div>
                                <div className="col-lg-2">
                                    <DropDownList
                                        label={t("License_Application_Label")}
                                        name="application"
                                        textField="applicationName"
                                        data={applicationsData}
                                        dataItemKey="applicationId"
                                        style={{ width: '100%' }}
                                        value={selectedApplication}
                                        onChange={filterAppId}
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <DatePicker
                                        format="d/MM/yyyy"
                                        label={t("Licenses_CreationDate_Label")}
                                        value={filterData.created || null} 
                                        onChange={filterCreationDate}
                                    />
                                </div>
                                <div className="col-lg-2" >
                                </div>

                                <div className="col-lg-2 d-flex">
                                    <button
                                        onClick={clickFilter}
                                        style={{ margin: "0" }}
                                        className="k-button ms-auto mt-auto mb-0"
                                    >
                                        <span className="k-icon k-i-filter" /> &nbsp; {t("Global_Button_Text_Filter")}
                                    </button>
                                </div>

                                <div className="col-lg-1 d-flex">
                                    <button
                                        onClick={clearFilters}
                                        style={{ margin: "0" }}
                                        className="k-button ms-auto mt-auto mb-0"
                                    >
                                        <span className="k-icon k-i-reset" /> &nbsp; {t("clear")}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                    <br />
                    {loading && loadingPanel}
                    <Grid
                        editField='inEdit'
                        cellRender={loadingCell}
                        pageable={{
                            pageSizes: ListOfPaged(),
                        }}
                        data={clientsData}
                        total={total}
                        skip={pageState.skip}
                        take={pageState.take}
                        scrollable={'none'}
                        sortable={{
                            allowUnsort: true,
                            mode: "single"
                        }}
                        sort={sortState}
                        onSortChange={sortChange}
                        onPageChange={pageChange}


                    >
                        {props.isAdmin ? <GridToolbar>
                            <div>
                                <Link to={'/LicenseRequests/Create'}>
                                    <button title={t("LicenseRequests_Button_Tooltip_Create")} className="k-button k-primary">
                                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                    </button>
                                </Link>
                            </div>
                        </GridToolbar> : ""}
                        <Column cell={CommandCell} width={props.isAdmin ? "200px" : "180px"} sortable={false} filterable={false} />
                        <Column field="creationDate" title={t("LicenseRequests_CreationDate_Label")} format="{0: dd/MM/yyyy}" filterable={false} />
                        <Column field="companyName" title={t("LicenseRequests_companyName_Label")} />
                        <Column field="appId" title={t("LicenseRequests_AppId_Label")} width="150px" />
                        <Column field="machineCode" title={t("LicenseRequests_MachineKey_Label")} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

