import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";


import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import { FloatingLabel } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { filterBy, toODataString } from '@progress/kendo-data-query';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { ListOfPaged, MinimumPage } from "../../classes/consts";
import { ProcessError, ErrorCode } from "../../classes/ExceptionProcessor";
import { CustomListCommandCellWithRemove } from "../../components/GridCustomListCommandCell";

import ClientsService from "../../services/clients.service";

import esMessages from '../../telerikResources/es.json';
import enMessages from '../../telerikResources/en.json';
import caMessages from '../../telerikResources/ca.json';

loadMessages(esMessages, 'es');
loadMessages(caMessages, 'ca');
loadMessages(enMessages, 'en');

export default function List(props) {

    const { t } = useTranslation();

    // Error Hooks
    const [visibleError, setVisibleError] = React.useState(false);
    const [errorData, setErrorData] = React.useState({ messageError: "", code: "" });

    // Data Hooks
    const [data, setData] = React.useState([]);

    // Grid Hooks
    const [orderByString, setOrderByString] = React.useState("?orderBy=name");
    const [sortState, setSortState] = React.useState([]);
    const [pageState, setPageState] = React.useState({ skip: 0, take: MinimumPage() });
    const [total, setTotal] = React.useState(0);

    // Delete Event Hooks
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [dataItemToRemove, setDataItemToRemove] = React.useState({});

    // Filter Hooks
    const [filterData, setFilterData] = React.useState({ isDisabled: false, clientName: "" });

    // Loading Hooks
    const [loading, setLoading] = React.useState(true);
    const [clientsFilter, setClientsFilter] = React.useState([]);
    const [loadingClients, setLoadingClients] = React.useState(true);
    const [clientsFilterNames, setClientsFilterNames] = React.useState([]);
    const [selectedClientFilter, setSelectedClientFilter] = React.useState("");
    const [stateClientFilter, setStateClientFilter] = React.useState({
        data: clientsFilterNames,
        value: "",
    });

    React.useEffect(() => {
        if (clientsFilter.length > 0) {
            const storedFilterState = sessionStorage.getItem("client_list");

            if (storedFilterState) {
                const client_list = JSON.parse(storedFilterState);

                // Establecer los filtros almacenados
                setSelectedClientFilter(client_list.clientFilter);
                setFilterData(prev => ({
                    ...prev,
                    clientName: client_list.clientName,
                    isDisabled: client_list.isDisabled,
                }));

                let clientId = client_list.clientFilter
                    ? client_list.clientFilter.replace(/ClientId eq (\d+)/, "$1")
                    : null;

                let clientSelected = clientsFilter.find(client => client.clientId === parseInt(clientId));

                if (clientSelected) {
                    setStateClientFilter({
                        data: filterDataByClient(clientSelected.name),
                        value: clientSelected.name, // Mostrar el nombre del cliente en el campo de autocompletado
                    });
                } else {
                    setStateClientFilter({
                        data: filterDataByClient(""),
                        value: "",
                    });
                }

                let filter = "";

                if (client_list.clientFilter !== "")
                    filter += client_list.clientFilter;

                if (client_list.clientName !== "")
                    filter += (filter ? " and " : "") + `Contains(ClientName, '${client_list.clientName}')`;

                if (client_list.isDisabled !== null)
                    filter += (filter ? " and " : "") + `IsDisabled eq ${client_list.isDisabled}`;

                console.log("Restored Filter:", filter);
                loadData(filter);
            }
        }
    }, [clientsFilter]);

    React.useEffect(() => {

        setLoadingClients(true);

        ClientsService.getClients()
            .then(response => response.data)
            .then(json => {
                json.splice(0, 0, { clientId: null, name: "", remarks: "" })
                setLoadingClients(false);
                json.splice(0, 0, { clientId: null, name: t("Licenses_ClientFilter_All_Text"), remarks: "" })
                setClientsFilter(json);
                setClientsFilterNames(json.map((e) => e.name));
                setLoadingClients(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });

    }, [t]);


    // Aspect functions

    const CommandCell = React.useCallback(cellProps => (
        <CustomListCommandCellWithRemove
            {...cellProps}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/clients/' + cellProps.dataItem.clientId + '/Details'}
            isAdmin={props.isAdmin}

        />
    ), [props]);

    const loadingPanel =
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
        ;
    const loadingCell = React.useCallback((tdElement, cellProps) => {
        if (cellProps.dataItem[cellProps.field] === undefined) {
            // shows loading cell if no data
            return <td> <span className="k-placeholder-line"></span></td>;
        }

        // default rendering for this cell
        return tdElement;
    }, []);

    // Data functions

    const remove = (dataItem) => {

        setDataItemToRemove(dataItem);
        setVisibleRemove(true);

    }

    const removeDataItem = async () => {

        setLoading(true);

        ClientsService.deleteClient(dataItemToRemove.clientId).then(() => {
            setDataItemToRemove({});
            loadData();
            setVisibleRemove(false);
        }).catch(err => {
            setDataItemToRemove({});
            setVisibleError(true);
            setVisibleRemove(false);
            setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) })
        });

    }

    const sortChange = React.useCallback((event) => {
        let oDataString = toODataString({ sort: event.sort });
        oDataString = oDataString.replace("name", "Name");
        oDataString = oDataString.replace("$orderby", "?orderBy");
        setOrderByString(oDataString);
        setSortState(event.sort);
    }, []);

    const pageChange = React.useCallback((event) => {
        setPageState({ ...pageState, skip: event.page.skip, take: event.page.take });
    }, [pageState]);



    const loadData = React.useCallback((filter) => {

        setLoading(true);

        ClientsService.getClientsFiltered(filter, orderByString, Math.ceil((pageState.skip + 1) / pageState.take), pageState.take)
            .then(response => response.data)
            .then(json => {
                json.contract.map((item) => {
                    let newDate = new Date(item.creationDate);
                    newDate.setHours(0, 0, 0, 0);
                    item.creationDate = newDate;
                    return item;
                })
                setData(json.contract);
                setTotal(json.totalResults);
                setLoading(false);
            }).catch((err) => {
                setVisibleError(true);
                setLoading(false);
                setErrorData({ messageError: ProcessError(err), code: ErrorCode(err) });
            });

    }, [pageState, orderByString]);

    // Effects nuevo agregado el loadData("IsDisabled eq false"); para que te muestre las companias que IsDisabled es false  
    React.useEffect(() => {
        loadData("IsDisabled eq false");

    }, [sortState, pageState, orderByString, loadData]);


    const clickFilter = () => {
        let filter = "";

        if (selectedClientFilter !== "")
            filter += selectedClientFilter;

        if (filterData.clientName !== "")
            if (filter === "")
                filter += 'Contains(ClientName,\'' + filterData.clientName + '\')';
            else
                filter += " and Contains(ClientName,'" + filterData.clientName + "')";

        if (filterData.active !== null)
            if (filter === "")
                filter += " IsDisabled eq " + (filterData.isDisabled);
            else
                filter += " and IsDisabled eq " + (filterData.isDisabled);

        loadData(filter);
        console.log(filter);

        const client_list = {
            clientFilter: selectedClientFilter,
            clientName: filterData.clientName,
            isDisabled: filterData.isDisabled,
        };

        sessionStorage.setItem("client_list", JSON.stringify(client_list));

    }

    const clearFilters = () => {
        sessionStorage.removeItem("client_list");
        setSelectedClientFilter(""); 
        setStateClientFilter({
            data: [], 
            value: "", 
        });
        setFilterData(prev => ({
            ...prev,
            clientName: "", 
            isDisabled: false, 
        }));
    
        loadData("IsDisabled eq false");
    };


    const filterIsDisabled = React.useCallback(e => {
        setFilterData(prev => ({ ...prev, isDisabled: e.value }));
    }, []);

    const filterDataByClient = React.useCallback((value) => {
        const filter = {
            value: value,
            operator: "startswith",
            ignoreCase: true,
        };

        return value ? filterBy(clientsFilterNames, filter) : clientsFilterNames;
    }, [clientsFilterNames]);


    //SE ENCARGA DE AUTOCOMPLETAR LA EMPRESA, ES UN CONST INDEPENDIENTE

    const FilterByClient = React.useCallback((client) => {

        console.log(client)

        let clientSelected = clientsFilter.find(obj => {
            return obj.name === client;
        })

        if (clientSelected != null && clientSelected?.name !== "") {

            console.log(clientSelected);

            setSelectedClientFilter("ClientId eq " + clientSelected.clientId);
            setStateClientFilter({
                data: filterDataByClient(clientSelected.name),
                value: clientSelected.name,
            });

        } else {

            setSelectedClientFilter("");
            setStateClientFilter({
                data: filterDataByClient(client),
                value: client,
            });
        }

    }, [clientsFilter, filterDataByClient]);

    const filterClient = React.useCallback(e => FilterByClient(e.target.value), [FilterByClient]);

    const closeError = React.useCallback(() => { setVisibleError(false) }, []);

    return (
        <div>
            {visibleError && (
                <Dialog title={"Error " + errorData.code} onClose={closeError}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{errorData.messageError}</b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => { setVisibleError(false) }}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {loading && loadingPanel}
            {visibleRemove && (
                <Dialog title={t("Global_RemoveDialog_Title")} onClose={closeError}>
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        <b>{t("Global_RemoveDialog_Text") + " \"" + dataItemToRemove.name + "\" ?"} </b>
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => removeDataItem()}>
                            {t("Global_OKButton_Text")}
                        </button>
                        <button className="k-button" onClick={() => { setVisibleRemove(false) }}>
                            {t("Global_CancelButton_Text")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <div className="card">
                <div className="card-body container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <FloatingLabel
                                style={{ width: "100%" }}
                                label={t("Contract_ClientName_Label")}
                                editorId={"clientNameFilterSelector"}
                                editorValue={loading ? "" : "null"}
                            >
                                <AutoComplete
                                    style={{ width: "100%" }}
                                    id={"clientsFilterSelector"}
                                    loading={loadingClients}
                                    data={stateClientFilter.data}
                                    value={stateClientFilter.value}
                                    onChange={filterClient}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="col-lg-1" >
                            <FloatingLabel
                                style={{ width: "100%" }}
                                label={t("Client_IsDisabled_Label")}
                                editorId={"isDisabledFilterSelector"}
                                editorValue={loading ? "" : "null"}
                            >
                                <Checkbox
                                    id={"isDisabledFilterSelector"}
                                    value={filterData.isDisabled}
                                    onChange={filterIsDisabled}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="col-lg-6" ></div>
                        <div className="col-lg-2 d-flex">

                            <button
                                onClick={clickFilter}
                                style={{ margin: "0" }}
                                className="k-button ms-auto mt-auto mb-0"
                            >
                                <span className="k-icon k-i-filter " /> &nbsp; {t("Global_Button_Text_Filter")}
                            </button>
                        </div>
                        <div className="col-lg-1 d-flex">
                            <button
                                onClick={clearFilters}
                                style={{ margin: "0" }}
                                className="k-button ms-auto mt-auto mb-0"
                            >
                                <span className="k-icon k-i-reset" /> &nbsp; {t("clear")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <LocalizationProvider language={localStorage.getItem("i18nextLng") === null ? "en" : localStorage.getItem("i18nextLng")}>
                <Grid
                    editField='inEdit'
                    cellRender={loadingCell}
                    pageable={{
                        pageSizes: ListOfPaged(),
                    }}
                    data={data}
                    total={total}
                    skip={pageState.skip}
                    take={pageState.take}
                    scrollable={'none'}
                    sortable={{
                        allowUnsort: true,
                        mode: "single"
                    }}
                    sort={sortState}
                    onSortChange={sortChange}
                    onPageChange={pageChange}

                >
                    {props.isAdmin ? <GridToolbar>
                        <div>
                            <Link to={'/clients/Create'}>
                                <button title={t("Clients_Button_Tooltip_Create")} className="k-button k-primary">
                                    &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_Create")} &nbsp;
                                </button>
                            </Link>
                        </div>
                    </GridToolbar> : ""}
                    <Column cell={CommandCell} width={props.isAdmin ? "130px" : "80px"} sortable={false} filterable={false} />
                    <Column field="name" title={t("Clients_Name_Label")} />
                </Grid>
            </LocalizationProvider>
        </div>
    );
}

