import React from 'react';
import Edit from '../../models/licenses/Edit';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LicenseService from "../../services/licenses.service";
import AuthService from "../../services/auth.service";

export default function EditLicenses(props) {

    const { t } = useTranslation();
    const { params } = props.match;
    const [license, setLicense] = React.useState({

        "allowUpdates": false,
        "appId": "",
        "creationDate": Date.now.toJSON,
        "licenseCode": "",
        "licenseId": -1,
        "isDisabled": false,
        "clientId": -1,
        "licenseRequestId": "",
        "machineCode": "",
        "remarks": "",
        "maxVersion": "",
        "clientName": ""

    });

    React.useEffect(() => {

        if (params.licenseId > -1) {
            LicenseService.getLicenseById(params.licenseId)
                .then(response => response.data)
                .then(json => {
                    setLicense({
                        "allowUpdates": json.contract.allowUpdates,
                        "appId": json.contract.appId,
                        "creationDate": json.contract.creationDate,
                        "licenseCode": json.contract.licenseCode,
                        "licenseId": json.contract.licenseId,
                        "isDisabled": json.contract.isDisabled,
                        "clientId": json.contract.clientId,
                        "licenseRequestId": json.contract.licenseRequestId,
                        "machineCode": json.contract.machineCode,
                        "remarks": json.contract.remarks ?? "",
                        "maxVersion": json.contract.maxVersion  ?? "",
                        "clientName": json.contract.clientName
                    });

                });
        }
        else {
            LicenseService.getLicenseByRequestId(params.licenseRequestId)
                .then(response => response.data)
                .then(json => {
                    setLicense({
                        "allowUpdates": json.contract.allowUpdates,
                        "appId": json.contract.appId,
                        "creationDate": json.contract.creationDate,
                        "licenseCode": json.contract.licenseCode,
                        "licenseId": json.contract.licenseId,
                        "isDisabled": json.contract.isDisabled,
                        "clientId": json.contract.clientId,
                        "licenseRequestId": json.contract.licenseRequestId,
                        "machineCode": json.contract.machineCode,
                        "remarks": json.contract.remarks ?? "",
                        "maxVersion": json.contract.maxVersion ?? "",
                        "clientName": json.contract.clientName
                    });

                });
        }

    }, [params.licenseId, params.licenseRequestId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenses/'}>{t("Licenses_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/licenses/' + license.licenseId + '/""/Details'}>{license.licenseId}</Link></li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Licenses/' + params.licenseId + '/Details/'} className="nav-link active">{t("Licenses_Details_Title")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Licenses/' + license.licenseId + '/Tags'} className="nav-link">{t("Licenses_Tags_Title")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Edit
                        license={license}
                        isAdmin={AuthService.isUserAdmin()}
                    />
                </div>
            </div>
        </div >
    );

}
